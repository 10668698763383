<template>
          <template v-if="state.datas">
            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Fonction</label>
              <div class="col-lg-8 col-12 fv-row">
                <input :disabled="!editData || !permission" @input="setNewData" type="text" v-model="state.datas.emp_fonction" class="form-control ps-4 min-w-md-400px" />
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">N° de sécurité sociale</label>
              <div class="col-lg-8 col-12 fv-row">
                <input type="text" v-model="state.datas.emp_num_securite_social" @input="setNewData" :disabled="!editData" class="form-control ps-4 min-w-md-400px" />
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">N+1</label>
              <div class="col-lg-8 col-12 fv-row">
                <select v-model="state.datas.emp_knum_n1" @input="setNewData" name="n1" :disabled="!editData || !permission" class="form-select form-select" :class="{'fw-bolder': !editData}" >
                  <option v-for="item, index in state.allUsers" :key="index" :value="item.emp_seq" :label="item.emp_nomprenom" name="personnen1" class="mb-3"/>
                </select>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Account Manager</label>
              <div class="col-lg-8 col-11 fv-row d-flex align-items-center gap-2">
                <input type="radio" name="accountManager" :disabled="!editData || !permission" @input="() => {state.datas.emp_x_am = 1; setNewData()}" :checked="state.datas.emp_x_am == 1" class="form-check-input w-10 h-10" ><span class="form-check-label me-3"> Oui </span>
                <input type="radio" name="accountManager" :disabled="!editData || !permission" @input="() => {state.datas.emp_x_am = 0; setNewData()}" :checked="state.datas.emp_x_am == 0" class="form-check-input w-10 h-10" ><span class="form-check-label"> Non </span>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Date de naissance</label>
              <div class="col-lg-3 col-7 fv-row">
                <input type="date" v-model="state.datas.emp_date_naissance" @input="setNewData" :disabled="!editData" class="form-control ps-7 w-225px text-center" />
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Date d'arrivée/départ</label>
              <div class="col-lg-3 col-7 fv-row">
                <input type="date" v-model="state.datas.emp_date_arrive" :disabled="!editData || !permission" @input="setNewData" class="form-control ps-7 w-225px text-center" />
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">% de Commission</label>
              <div class="col-lg-3 col-7 fv-row">
                <div class="input-group w-225px">
                  <input type="text" class="form-control text-end" :disabled="!editData || !permission" @input="setNewData" v-model="state.datas.emp_pourc_com" aria-label="Amount (to the nearest dollar)"/>
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Salaire brut mens</label>
              <div class="col-lg-4 col-8 fv-row">
                <div class="input-group w-225px">
                  <input type="text" class="form-control text-end" :disabled="!editData || !permission" @input="setNewData" v-model="state.datas.emp_salaire_brut" aria-label="Amount (to the nearest dollar)"/>
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Utilisateur CRM</label>
              <div class="col-lg-5 col-8 fv-row">
                <select @input="e => {state.datas.emp_knum_user = e.target.value; setNewData()}" name="CRM" :disabled="!editData || !permission" class="form-select form-select" :class="{'fw-bolder': !editData}" >
                  <option v-for="item, index in state.CRMusers" :key="index" :value="item.us_seq" :selected="item.us_seq == state.datas.emp_knum_user" :label="item.logintxt" name="personne" class="mb-3"/>
                </select>
              </div>
            </div>
        </template>
</template>

<script lang="ts">
import mAxiosApi from "@/api";
import { defineComponent, onMounted, reactive, watch} from "vue";

export default defineComponent({
  name: "Generalinfos",
  props: {
    datas: Object,
    permission: Boolean,
    editData: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: {...props.datas},
      allUsers: [] as any,
      CRMusers: [] as any,
    })

    watch(() => props.datas, (newData) => {
      state.datas = {...newData};
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    onMounted(async() => {
      const getUsersCRM = await mAxiosApi.prototype.getAxios("/getUsersCRM");
      const getAllUsers = await mAxiosApi.prototype.getAxios("/getAllUsers");
      state.allUsers = getAllUsers.results;
      state.CRMusers = getUsersCRM.results;
    })

    return{
      state,
      setNewData
    }
  },
});
</script>
