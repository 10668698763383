
import mAxiosApi from "@/api";
import { defineComponent, onMounted, reactive, watch} from "vue";

export default defineComponent({
  name: "Generalinfos",
  props: {
    datas: Object,
    permission: Boolean,
    editData: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: {...props.datas},
      allUsers: [] as any,
      CRMusers: [] as any,
    })

    watch(() => props.datas, (newData) => {
      state.datas = {...newData};
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    onMounted(async() => {
      const getUsersCRM = await mAxiosApi.prototype.getAxios("/getUsersCRM");
      const getAllUsers = await mAxiosApi.prototype.getAxios("/getAllUsers");
      state.allUsers = getAllUsers.results;
      state.CRMusers = getUsersCRM.results;
    })

    return{
      state,
      setNewData
    }
  },
});
