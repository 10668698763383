
import mAxiosApi from "@/api";
import { defineComponent, onMounted, reactive, watch} from "vue";

export default defineComponent({
  name: "Generalinfos",
  props: {
    datas: Array,
    permission: Boolean,
    editData: Boolean,
    addAdresse: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: [...props.datas as any],
      newAdresse: {},
      typesAdresse: [] as any,
    })

    watch(() => props.datas, (newData) => {
      state.datas = [...newData as any];
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    const setNewAdresse = () => {
      emit("setNewAdresse", state.newAdresse)
    }

    onMounted(async() => {
      const typesAdresse = await mAxiosApi.prototype.getAxios("/getTypesAdresse");
      state.typesAdresse = typesAdresse.results;
    });

    return{
      state,
      setNewData,
      setNewAdresse
    }
  },
});
