<template>

    <template v-if="state.datas">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Nom de la banque</label>
        <div class="col-lg-8 col-12 fv-row">
          <input type="text" v-model="state.datas.emp_rb_nom_banque" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">IBAN / SWIFT</label>
        <div class="col-lg-5 col-7 fv-row">
          <input type="text" v-model="state.datas.emp_rb_iban" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
        <div class="col-lg-3 col-5 fv-row">
          <input type="text" v-model="state.datas.emp_rb_swift" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Adresse de la banque</label>
        <div class="col-lg-8 col-12 fv-row">
          <input type="text" v-model="state.datas.emp_rb_adresse_banque" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">CP / Ville / Pays</label>
        <div class="col-lg-2 col-3 fv-row">
          <input type="text" v-model="state.datas.emp_rb_cp" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
        <div class="col-lg-3 col-5 fv-row">
          <input type="text" v-model="state.datas.emp_rb_ville" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
        <div class="col-lg-3 col-4 fv-row">
         <input type="text" v-model="state.datas.emp_rb_pays" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Code banque</label>
        <div class="col-lg-5 col-8 fv-row">
          <input type="text" v-model="state.datas.emp_rb_code_banque" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Compte banque / Clé RIB</label>
        <div class="col-lg-7 col-10 fv-row">
          <input type="text" v-model="state.datas.emp_rb_cpt_banque" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
        <div class="col-lg-1 col-2 fv-row">
          <input type="text" v-model="state.datas.emp_rb_clef_rib" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>
    </template>

</template>

<script lang="ts">
import { defineComponent, reactive, watch} from "vue";

export default defineComponent({
  name: "BancairesRefs",
  props: {
    datas: Object,
    permission: Boolean,
    editData: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: {...props.datas},
    })

    watch(() => props.datas, (newData) => {
      state.datas = {...newData};
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    return{
      state,
      setNewData
    }
  },
});
</script>
