
import { defineComponent, onMounted, reactive, watch} from "vue";

export default defineComponent({
  name: "ContactInfos",
  props: {
    datas: Object,
    permission: Boolean,
    editData: Boolean,
    addObjo: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: [...props.datas as any] as any,
      newObjo: {} as any,
    })

    watch(() => props.datas, (newData) => {
      state.datas = [...newData as any];
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    const setNewObjo = () => {
      emit("setNewObjo", state.newObjo)
    }

    const getFormattedTotal = (value) => {
      const n = (Math.ceil(value*100)/100).toFixed(2);
      return commify(n) + ' €';
    }

    function commify(n) {
      var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, "'") + (decimalPart ? "." + decimalPart : "");
    }

    return{
      state,
      getFormattedTotal,
      setNewData,
      setNewObjo
    }
  }
});
