
import { defineComponent, reactive, watch} from "vue";

export default defineComponent({
  name: "BancairesRefs",
  props: {
    datas: Object,
    permission: Boolean,
    editData: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: {...props.datas},
    })

    watch(() => props.datas, (newData) => {
      state.datas = {...newData};
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    return{
      state,
      setNewData
    }
  },
});
