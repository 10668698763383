<template>
  <div class="py-0">
    <div class="table-responsive">
      <table class="table table-row-dashed">
        <tbody>
          <tr v-if="addAdresse">
            <div class="row mb-1 w-100">
              <label class="col-lg-4 col-0 col-form-label fw-semobold fs-6">Type d'adresse</label>
              <div class="col-lg-8 col-12 fv-row">
                <select v-model="state.newAdresse.tadr_seq" @input="setNewAdresse" name="tadresse" class="form-select form-select" >
                  <option v-for="item, index in state.typesAdresse" :key="index" :value="item.tadr_seq" :label="item.tadr_libelle" name="type" class="mb-3"/>
                </select>
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Adresse</label>
              <div class="col-lg-8 col-12 fv-row">
                <input type="text" v-model="state.newAdresse.adr_ligne_1" @input="setNewAdresse" class="form-control ps-4" />
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Complément</label>
              <div class="col-lg-8 col-12 fv-row">
                <input type="text" v-model="state.newAdresse.adr_complement" @input="setNewAdresse" class="form-control ps-4" />
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">CP / Ville / Pays</label>
              <div class="col-lg-2 col-3 fv-row">
                <input type="text" v-model="state.newAdresse.adr_cp" @input="setNewAdresse" class="form-control ps-4" />
              </div>
              <div class="col-lg-3 col-5 fv-row">
                <input type="text" v-model="state.newAdresse.adr_ville" @input="setNewAdresse" class="form-control ps-4" />
              </div>
              <div class="col-lg-3 col-4 fv-row">
                <input type="text" v-model="state.newAdresse.adr_pays" @input="setNewAdresse" class="form-control ps-4" />
              </div>
            </div>
          </tr>

          <tr v-for="item, index in state.datas" :key="index">

            <div class="row mb-1 w-100">
              <label class="col-lg-4 col-0 col-form-label fw-semobold fs-6">Type d'adresse</label>
              <div class="col-lg-8 col-12 fv-row">
                <input v-if="!editData" type="text" v-model="item.tadr_libelle" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
                <select v-else v-model="item.tadr_seq" @input="setNewData" :disabled="!editData" name="tadresse" class="form-select form-select" >
                  <option v-for="item, index in state.typesAdresse" :key="index" :value="item.tadr_seq" :label="item.tadr_libelle" name="type" class="mb-3"/>
                </select>
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Adresse</label>
              <div class="col-lg-8 col-12 fv-row">
                <input type="text" v-model="item.adr_ligne_1" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Complément</label>
              <div class="col-lg-8 col-12 fv-row">
                <input type="text" v-model="item.adr_complement" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">CP / Ville / Pays</label>
              <div class="col-lg-2 col-3 fv-row">
                <input type="text" v-model="item.adr_cp" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
              <div class="col-lg-3 col-5 fv-row">
                <input type="text" v-model="item.adr_ville" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
              <div class="col-lg-3 col-4 fv-row">
                <input type="text" v-model="item.adr_pays" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
            </div>
          </tr>
        </tbody>
      </table>
      <el-empty v-if="state.datas.length == 0 && !addAdresse" description="Aucune adresse"></el-empty>
    </div>
  </div>
</template>

<script lang="ts">
import mAxiosApi from "@/api";
import { defineComponent, onMounted, reactive, watch} from "vue";

export default defineComponent({
  name: "Generalinfos",
  props: {
    datas: Array,
    permission: Boolean,
    editData: Boolean,
    addAdresse: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: [...props.datas as any],
      newAdresse: {},
      typesAdresse: [] as any,
    })

    watch(() => props.datas, (newData) => {
      state.datas = [...newData as any];
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    const setNewAdresse = () => {
      emit("setNewAdresse", state.newAdresse)
    }

    onMounted(async() => {
      const typesAdresse = await mAxiosApi.prototype.getAxios("/getTypesAdresse");
      state.typesAdresse = typesAdresse.results;
    });

    return{
      state,
      setNewData,
      setNewAdresse
    }
  },
});
</script>