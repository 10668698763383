<template>

    <template v-if="state.datas">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Téléphones</label>
        <div class="col-lg-4 col-6 fv-row">
          <input type="text" v-model="state.datas.emp_tel" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
        <div class="col-lg-4 col-6 fv-row">
          <input type="text" v-model="state.datas.emp_tel2" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Adresse e-mail personnelle</label>
        <div class="col-lg-8 col-12 fv-row">
          <input type="text" v-model="state.datas.emp_mail_perso" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Adresse e-mail professionnelle</label>
        <div class="col-lg-8 col-12 fv-row">
          <input type="text" v-model="state.datas.emp_mail_pro" @input="setNewData" :disabled="!editData || !permission" class="form-control ps-4"/>
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Contact à prévenir</label>
        <div class="col-lg-8 col-12 fv-row">
          <input type="text" v-model="state.datas.emp_nom_personne_a_prevenir" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-semobold fs-6">Tel contact à prévenir</label>
        <div class="col-lg-4 col-7 fv-row">
          <input type="text" v-model="state.datas.emp_tel_personne_a_prevenir" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
        </div>
      </div>
    </template>

</template>

<script lang="ts">
import { defineComponent, reactive, watch} from "vue";

export default defineComponent({
  name: "ContactInfos",
  props: {
    datas: Object,
    permission: Boolean,
    editData: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: {...props.datas},
    })

    watch(() => props.datas, (newData) => {
      state.datas = {...newData};
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    return{
      state,
      setNewData
    }
  }
});
</script>
