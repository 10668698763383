<template>
  <div class="py-0">
    <div class="table-responsive">
      <table class="table table-row-dashed">
        <tbody>
          <tr v-if="addObjo">
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Montant à réaliser</label>
              <div class="col-lg-8 col-12 fv-row">
                <input type="text" v-model="state.newObjo.oem_montant" @input="setNewObjo" class="form-control ps-4" />
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Date début / Date fin</label>
              <div class="col-lg-4 col-6 fv-row">
                <input type="date" v-model="state.newObjo.oem_date_debut" @input="setNewObjo" class="form-control ps-4" />
              </div>
              <div class="col-lg-4 col-6 fv-row">
                <input type="date" v-model="state.newObjo.oem_date_fin" @input="setNewObjo" class="form-control ps-4" />
              </div>
            </div>
          </tr>
          <tr v-for="item, index in state.datas" :key="index">
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Montant à réaliser</label>
              <div class="col-lg-8 col-12 fv-row">
                <input type="text" v-model="item.oem_montant" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
            </div>
            <div class="row mb-2 w-100">
              <label class="col-lg-4 col-form-label fw-semobold fs-6">Date début / Date fin</label>
              <div class="col-lg-4 col-6 fv-row">
                <input type="date" v-model="item.oem_date_debut" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
              <div class="col-lg-4 col-6 fv-row">
                <input type="date" v-model="item.oem_date_fin" @input="setNewData" :disabled="!editData" class="form-control ps-4" />
              </div>
            </div>
          </tr>
        </tbody>
      </table>
      <el-empty v-if="state.datas.length == 0 && !addObjo" description="Aucun objectif"></el-empty>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch} from "vue";

export default defineComponent({
  name: "ContactInfos",
  props: {
    datas: Object,
    permission: Boolean,
    editData: Boolean,
    addObjo: Boolean,
  },

  setup(props, {emit}) {
    const state = reactive({
      datas: [...props.datas as any] as any,
      newObjo: {} as any,
    })

    watch(() => props.datas, (newData) => {
      state.datas = [...newData as any];
    });

    const setNewData = () => {
      emit("setNewData", state.datas)
    }

    const setNewObjo = () => {
      emit("setNewObjo", state.newObjo)
    }

    const getFormattedTotal = (value) => {
      const n = (Math.ceil(value*100)/100).toFixed(2);
      return commify(n) + ' €';
    }

    function commify(n) {
      var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, "'") + (decimalPart ? "." + decimalPart : "");
    }

    return{
      state,
      getFormattedTotal,
      setNewData,
      setNewObjo
    }
  }
});
</script>
